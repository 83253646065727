var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sp-component sp-transfer-list"},[_c('p',[_vm._v("sgsfgdss")]),_vm._m(0),(_vm.bankAddress && _vm.transactions.length > 0)?_c('table',{staticClass:"sp-transfer-list__table sp-box sp-shadow"},[_vm._m(1),_c('tbody',_vm._l((_vm.sentTransactions),function(tx,index){return _c('tr',{key:index},[_c('p',[_vm._v(" TEST ")]),_c('td',{staticClass:"sp-transfer-list__status"},[_c('div',{staticClass:"sp-transfer-list__status__wrapper"},[_vm._m(2,true),_c('div',{staticClass:"sp-transfer-list__status__action"},[_c('div',{staticClass:"sp-transfer-list__status__action__text"},[_vm._v(" "+_vm._s(_vm.getTxText(tx))+" ")])])])]),_c('td',{staticClass:"sp-transfer-list__table__address"},[_c('p',[_vm._v(" TTTEST ")]),_vm._v(" "+_vm._s(_vm.getTxDetails(tx))+" ")]),(
							tx.body.messages[0]['@type'] == '/cosmos.bank.v1beta1.MsgSend'
						)?_c('td',{staticClass:"sp-transfer-list__table__amount"},_vm._l((tx.body.messages[0].amount),function(token,index){return _c('div',{key:'am' + index},[_vm._v(" "+_vm._s(tx.body.messages[0].from_address == _vm.bankAddress ? '-' + token.amount + ' ' + token.denom.toUpperCase() : '+' + token.amount + ' ' + token.denom.toUpperCase())+" ")])}),0):(
							tx.body.messages[0]['@type'] ==
							'/ibc.applications.transfer.v1.MsgTransfer'
						)?_c('td',{staticClass:"sp-transfer-list__table__amount"},[_c('div',[_vm._v(" "+_vm._s(tx.body.messages[0].sender == _vm.bankAddress ? '-' + tx.body.messages[0].token.amount + ' ' + tx.body.messages[0].token.denom.toUpperCase() : '+' + tx.body.messages[0].token.amount + ' ' + tx.body.messages[0].token.denom.toUpperCase())+" ")])]):(
							tx.body.messages[0]['@type'] ==
							'/ibc.core.channel.v1.MsgRecvPacket'
						)?_c('td',{staticClass:"sp-transfer-list__table__amount"},[_c('div',[_vm._v(" "+_vm._s(_vm.getDecoded(tx.body.messages[0].packet.data).receiver == _vm.bankAddress ? '+' + _vm.getDecoded(tx.body.messages[0].packet.data).amount + ' IBC/' + tx.body.messages[0].packet.destination_port.toUpperCase() + '/' + tx.body.messages[0].packet.destination_channel.toUpperCase() + '/' + _vm.getDecoded( tx.body.messages[0].packet.data ).denom.toUpperCase() : '-' + _vm.getDecoded(tx.body.messages[0].packet.data).amount + ' IBC/' + tx.body.messages[0].packet.destination_port.toUpperCase() + '/' + tx.body.messages[0].packet.destination_channel.toUpperCase() + '/' + _vm.getDecoded( tx.body.messages[0].packet.data ).denom.toUpperCase())+" ")])]):_c('td',{staticClass:"sp-transfer-list__table__amount"})])}),0)]):_c('table',{staticClass:"sp-transfer-list__table sp-box sp-shadow"},[_c('tbody',[_c('tr',[_c('td',{staticClass:"sp-transfer-list__status"},[_c('div',{staticClass:"sp-transfer-list__status__wrapper"},[_vm._m(3),_c('div',{staticClass:"sp-transfer-list__status__action"},[_c('div',{staticClass:"sp-transfer-list__status__action__text"},[_vm._v(" No transactions yet ")]),(!_vm.bankAddress)?_c('div',{staticClass:"sp-transfer-list__status__action__date"},[_vm._v(" Add or unlock a wallet to see recent transactions ")]):_vm._e()])])]),_c('td',{staticClass:"sp-transfer-list__table__address"}),_c('td',{staticClass:"sp-transfer-list__table__amount"})])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sp-transfer-list__header sp-component-title"},[_c('h3',[_vm._v("Transactions")]),_c('span',[_vm._v("|")]),_c('span',[_vm._v("A list of your recent transactions")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"sp-transfer-list__status"},[_vm._v("STATUS")]),_c('th',{staticClass:"sp-transfer-list__table__address"},[_vm._v("ADDRESS / DETAILS")]),_c('th',{staticClass:"sp-transfer-list__table__amount"},[_vm._v("AMOUNT")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('span')])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sp-transfer-list__status__icon sp-transfer-list__status__icon__empty"},[_c('span',{staticClass:"sp-icon sp-icon-Transactions"})])}]

export { render, staticRenderFns }